<template>
  <div>
    <div
      v-if="loading"
      class="is-flex ion-align-items-center ion-justify-content-center pt-4"
    >
      <IonSpinner/>
    </div>

    <company-podcasts
      v-for="(data, i) in library"
      :key="`library-${i}`"
      :company-details="data.company"
    >
      <SeriesPodcast
        v-for="(course, y) in data.courses"
        :key="`series-${y}`"
        :course-data="course"
        @click="$router.push(`/course/${course.id}`)"
      />
    </company-podcasts>

    <ion-infinite-scroll
      @ionInfinite="loadMore($event)"
      :key="`x-${loadMoreDisabled}`"
      :disabled="loadMoreDisabled"
    >
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        :loading-text="`${$t('commonKeys.loading')}...`"
      />
    </ion-infinite-scroll>
  </div>
</template>

<script>

import CompanyPodcasts from '@/components/common/CompanyPodcasts.vue';
import SeriesPodcast from '@/components/common/SeriesPodcast.vue';
import { useStore } from "vuex";
import {computed, ref} from "@vue/reactivity";
import { waitTime } from "@/helpers/functions";

import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSpinner,
} from '@ionic/vue';

export default {
  name: 'MainLibrary',
  components: {
    CompanyPodcasts,
    SeriesPodcast,
    IonSpinner,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  },

  setup() {
    const store = useStore();
    const loading = ref(false);

    const library = computed(() => store.getters['library/getVisibleLibrary']);

    loading.value = true;
    store.dispatch('library/setLibrary')
      .finally(() => {
        loading.value = false;
      });

    const loadMoreDisabled = computed(() => {
      return store.state.library.library.length >= store.state.library.libraryAll.length;
    });


    const loadMore = async (ev) => {
      await waitTime(250);
      await store.commit('library/loadLibraryRecords');
      ev.target.complete();
    };

    return {
      library,
      loadMoreDisabled,
      loadMore,
      loading,

    };
  },
};
</script>
