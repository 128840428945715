<template>
  <div class="pt-4">
    <div
      v-if="loading"
      class="is-flex ion-align-items-center ion-justify-content-center pt-4"
    >
      <IonSpinner/>
    </div>

    <h3
      v-if="!loading && !streams.length"
      class="ion-margin-top ion-text-center"
    > {{ $t('library.accreditationPlaceholder') }}
    </h3>

    <div
      v-for="(stream, y) in streams"
      :key="`stream-${y}`"
      class="mb-12"
    >
      <SinglePodcast
        :podcast-data="stream"
        :course-media="stream.course.media"
        @click="openPodcast(stream)"
      />
      <ion-button
        v-for="(form, cIndex) in stream.forms.filter(form => form.passed)"
        color="primary"
        :key="cIndex"
        mode="ios"
        @click="emailCertificate(form)"
      >
        {{ $t('accreditation.emailCertificate') }}
      </ion-button>
    </div>



  </div>
</template>

<script>

import SeriesPodcast from '@/components/common/SeriesPodcast.vue';
import { useStore } from "vuex";
import { computed, ref } from "@vue/reactivity";
import { IonButton } from '@ionic/vue';
import cloneDeep from 'lodash/cloneDeep';
import useRequestToast from "@/composables/useRequestToast";
import { useI18n } from 'vue-i18n/index';

import {
  IonSpinner,
} from '@ionic/vue';
import SinglePodcast from "@/components/common/SinglePodcast";

export default {
  name: 'AccreditationOverview',
  components: {
    SinglePodcast,
    SeriesPodcast,
    IonSpinner,
    IonButton,
  },

  setup() {
    const store = useStore();
    const { t } = useI18n();
    const { openErrorToast, openSuccessToast } = useRequestToast();


    const loading = ref(false);

    const streams = computed(() => {
      const allStreams = cloneDeep(store.state.accreditation.streamsWithAccreditation);
      return allStreams.filter(stream => {
        const passedForms = stream.forms.filter(form => form.passed);
        return passedForms.length > 0;
      });
    });

    loading.value = true;
    store.dispatch('accreditation/setAccreditationStreams')
      .finally(() => {
        loading.value = false;
      });


    const openPlayer = (stream, course) => {
      const startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
      });
    };

    const openPodcast = (stream) => {
      store.dispatch('course/setCourseData', stream.course_id)
        .then((course) => {
          openPlayer(stream, course);
        });
    };

    const emailCertificate = (form) => {
      store.dispatch('accreditation/sendCertificateToUserEmail', form.form_id)
        .then(() => {
          openSuccessToast(t('commonKeys.successfullySent'));
        })
        .catch(openErrorToast)
        .finally(() => {
          loading.value = false;
        });
    };


    return {
      streams,
      loading,
      openPodcast,
      emailCertificate,
    };
  },
};
</script>
