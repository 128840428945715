<template>
  <div class="new-releases-component">
    <div
      v-if="loading"
      class="is-flex ion-align-items-center ion-justify-content-center pt-4"
    >
      <IonSpinner/>
    </div>

    <template
      v-for="(course, y) in releases"
      :key="`course-${y}`"
    >
      <CompanyPodcasts :company-details="course.company"/>
      <SeriesPodcast
        :course-data="course"
        @click="$router.push(`/course/${course.id}`)"
      />
    </template>

    <ion-infinite-scroll
      @ionInfinite="loadMore($event)"
      :key="`x-${loadMoreDisabled}`"
      :disabled="loadMoreDisabled"
    >
      <ion-infinite-scroll-content
        loading-spinner="bubbles"
        :loading-text="`${$t('commonKeys.loading')}...`"
      />
    </ion-infinite-scroll>
  </div>
</template>

<script>
import CompanyPodcasts from '@/components/common/CompanyPodcasts.vue';
import SinglePodcast from '@/components/common/SinglePodcast.vue';
import { useStore } from "vuex";
import { ref, computed } from "@vue/reactivity";
import {
  IonInfiniteScroll,
  IonRefresher,
  IonRefresherContent,
  IonSpinner,
  IonInfiniteScrollContent,
} from '@ionic/vue';
import SeriesPodcast from "@/components/common/SeriesPodcast";


export default {
  name: 'NewReleases',
  components: {
    SeriesPodcast,
    CompanyPodcasts,
    SinglePodcast,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
  },

  setup() {
    const store = useStore();

    const loading = ref(false);


    const allCourses = computed(() => store.state.newReleases.allCourses);

    const releases = computed(() => store.getters['newReleases/getNewReleases']);
    const loadMoreDisabled = computed(() => {
      return store.state.newReleases.originCurrentRecordsCount >= store.state.newReleases.totalRecords;
    });



    if (!store.state.newReleases.dataFetched) {
      loading.value = true;
      store.dispatch('newReleases/setNewReleases', false)
        .finally(() => {
          loading.value = false;
        });
    }

    const getCourse = (stream) => {
      const courses = store.state.newReleases.allCourses ?? [];
      return courses.find(course => course.id === stream.course_id) ?? {};
    };

    const loadMore = async (ev) => {
      await store.dispatch('newReleases/setNewReleases', true)
        .then(() => {
          ev.target.complete();
        });
    };

    // const openPlayer = (stream, release) => {
    //   const startAt = release.streams.findIndex(companyStream => companyStream.id === stream.id);
    //   store.dispatch('player/openPlayer', {
    //     company: release.company,
    //     playlist: release.streams,
    //     course: getCourse(stream),
    //     startAt,
    //   });
    // };


    const openPlayer = (stream, course) => {
      const startAt = course.streams.findIndex(companyStream => companyStream.id === stream.id);
      store.dispatch('player/openPlayer', {
        course,
        company: course.company,
        playlist: course.streams,
        startAt,
      });
    };

    const openPodcast = (stream) => {
      store.dispatch('course/setCourseData', stream.course_id)
        .then((course) => {
          openPlayer(stream, course);
        });
    };


    return {
      loading,
      loadMore,
      openPlayer,
      loadMoreDisabled,
      getCourse,
      allCourses,
      openPodcast,
      releases,
    };
  },
};
</script>
