<template>
  <ion-page>
    <ion-header class="profile-header">
      <ion-toolbar class="profile-toolbar">
        <ion-segment
          mode="md"
          :value="currentTab"
          class="segment"
          @ionChange="segmentChanged($event)"
        >
          <ion-segment-button
            class="segment-button"
            :value="TABS.library"
          >
            <ion-label class="label">{{ $t('library.library') }}</ion-label>
          </ion-segment-button>
          <ion-segment-button
            class="segment-button"
            :value="TABS.new_releases"
          >
            <ion-label class="label">{{ $t('library.new') }}</ion-label>
          </ion-segment-button>

          <!--          <ion-segment-button-->
          <!--            class="segment-button"-->
          <!--            :value="TABS.accreditation"-->
          <!--          >-->
          <!--            <ion-label class="label">{{ $t('library.accreditation') }}</ion-label>-->
          <!--          </ion-segment-button>-->
        </ion-segment>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="isLoading" >
      <div class="is-flex ion-align-items-center ion-justify-content-center h-100">
        <IonSpinner/>
      </div>
    </ion-content>
    <ion-content
      v-else
      class="ion-padding-start ion-padding-end pt-5"
    >
      <ion-refresher
        slot="fixed"
        @ionRefresh="refresh($event)"
      >
        <ion-refresher-content/>
      </ion-refresher>

      <div class="h-100 tablet-centered">
        <MainLibrary
          v-show="currentTab === TABS.library"
          ref="library"
        />
        <NewReleases
          v-show="currentTab === TABS.new_releases"
          ref="new-releases"
        />

        <AccreditationOverview v-if="currentTab === TABS.accreditation" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import MainLibrary from '@/components/library/MainLibrary.vue';
import NewReleases from '@/components/library/NewReleases.vue';

import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  IonRouterOutlet,
  IonSpinner,
  onIonViewWillEnter,
  onIonViewDidEnter,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/vue';

import { ref } from '@vue/reactivity';

import { useRouter, useRoute } from 'vue-router';
import {useStore} from "vuex";
import useTracking from "@/composables/useTracking";
import {TRACKING_LIBRARY, TRACKING_NEW_RELEASES} from "@/config/constants";
import AccreditationOverview from "@/components/library/AccreditationsOverview";

export default {
  name: 'Library',
  components: {
    AccreditationOverview,
    IonPage,
    IonContent,
    IonHeader,
    IonToolbar,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    MainLibrary,
    NewReleases,
    IonIcon,
    IonRouterOutlet,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { trackThis } = useTracking();


    const TABS = {
      library: 'library',
      new_releases: 'new-releases',
      accreditation: 'accreditation',
    };

    const currentTab = ref(TABS.library);
    const isLoading = ref(true);


    const trackTab = () => {
      switch (currentTab.value) {
      case TABS.library : trackThis(TRACKING_LIBRARY); break;
      case TABS.new_releases: trackThis(TRACKING_NEW_RELEASES); break;
      }
    };

    const segmentChanged = (ev) => {
      currentTab.value = ev.detail.value;
      trackTab();
    };

    onIonViewWillEnter(() => {
      if (route.query.tab) {
        currentTab.value = route.query.tab;
        router.replace(route.path);
      }
    });
    onIonViewDidEnter(() => {
      if (isLoading) {
        setTimeout(() => {
          isLoading.value = false;
          trackTab();
        }, 0);
      }
    });


    const refresh = async (ev) => {
      if (currentTab.value === TABS.new_releases) {
        return await store.dispatch('newReleases/setNewReleases', false)
          .finally(() => {
            ev.target.complete();
          });
      }

      if (currentTab.value === TABS.library) {
        return await store.dispatch('library/setLibrary')
          .finally(() => {
            ev.target.complete();
          });
      }

      if (currentTab.value === TABS.accreditation) {
        return await store.dispatch('accreditation/setAccreditationStreams')
          .finally(() => {
            ev.target.complete();
          });
      }

      ev.target.complete();
    };

    return {
      segmentChanged,
      currentTab,
      isLoading,
      refresh,
      TABS,
    };
  },
};
</script>

<style lang="scss" scoped>
ion-tab-button {
  --color: red;
}



.segment {
  --background: none;
}

ion-segment-button::part(indicator-background) {
  border-radius: 12px;
}

.label {
  font-size: 20px;
}
</style>
