<template>
  <div class="company-podcasts ion-padding-vertical">
    <CompanyDetails :company-details="companyDetails" />
    <slot></slot>
  </div>
</template>

<script>
import CompanyDetails from '@/components/common/CompanyDetails.vue';

export default {
  name: 'CompanyPodcasts',
  components: {
    CompanyDetails,
  },
  props: {
    companyDetails: {
      type: Object,
      required: true,
    },
  },
};
</script>

